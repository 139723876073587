/**
  This is the My Activites view which displays and manages notes, tasks, and check-ins
*/
<template>
    <Modal
        data-cy="category-modal"
        class="category-modal"
        ref="categoryModal"
        :title="`${editingCategory ? 'Edit' : 'Add'} Category`"
        instructions=""
        @success="addOrUpdateCategory()"
    >
        <div class="task-title">
            Category Title
            <input 
                class="add-task-input"
                data-cy="add-cat-input"
                type="text" 
                placeholder="Type here..." 
                v-model="categoryTitle"
            >      
        </div>
        <div class="category-color">
            Color
            <input
                data-cy="task-color-input" 
                class="color-input" 
                type="color" 
                v-model="categoryColor"
            >
        </div>
    </Modal>
    <div id="activities">
        <Sidebar>
            <div class="sidebar-section">
                <div class="sidebar-section-title">
                    Choose View
                </div>
                <CustomSelect
                    data-cy="activity-types-select"
                    :options="activityTypes"
                    v-model="mode"
                />
                <div v-if="mode==='Tasks'" class="show-completed-area">
                    <input type="checkbox" name="showCompleted" id="showCompleted" v-model="showCompleted" data-cy="show-completed-tasks">
                    <label for="showCompleted">Show completed tasks</label>
                </div>
                <CustomButton
                    data-cy="new-activity"
                    @click="launchAdd"
                    :buttonText="`New ${mode.slice(0,mode.length-1)}`" 
                    @mouseover="showTooltip(`New ${mode.slice(0,mode.length-1)}`)"
                    @mouseleave="showTooltip('')"
                />
            </div>
            <div class="sidebar-section" v-if="mode!=='Check-ins'">
                <div class="sidebar-section-title">
                    Categories
                </div>
                <CustomButton
                    @click="launchAddCategory"
                    data-cy="add-new-category"
                    buttonText="New Category" 
                    @mouseover="showTooltip('New Category')"
                    @mouseleave="showTooltip('')"
                />
                <div 
                    v-for="(category, index) in categories" :key="index" 
                    class="category-entry"
                    data-cy="category-list"
                >
                    <span 
                        class="category-bubble" 
                        data-cy="category-bubble" 
                        :style="getCategoryStyle(category)"
                    >
                        {{ category.title }}
                    </span>
                    <span 
                        class="material-icons edit-category-button" 
                        data-cy="edit-category-button"
                        @click="launchEditCategory(category)"
                        @mouseover="showTooltip('Edit Category')"
                        @mouseleave="showTooltip('')"
                        title="Edit Category"
                    >
                        edit
                    </span>
                    <span
                        class="delete-message-button" 
                        data-cy="delete-category-button"
                        @click="deleteCategory(category)"
                        @mouseover="showTooltip('Delete Category')"
                        @mouseleave="showTooltip('')"
                        title="Delete Category"
                    >
                        ✖
                    </span>
                </div>
            </div>
            <div class="manager-area" v-if="mode==='Check-ins'">
                <input type="checkbox" id="managerCheckbox" name="managerCheckbox" v-model="managerMode" data-cy="check-in-manager-mode">
                <label for="managerCheckbox">View in manager mode</label>
            </div>
            <CustomButton
                v-if="managerMode"
                @click="download"
                buttonText="Download Check-ins" 
                @mouseover="showTooltip('Download Check-ins')"
                @mouseleave="showTooltip('')"
            />
            <hr class="sidebar-division">
            <Info />
        </Sidebar>
        <div class="main-area-narrow main-area-indented">
            <MyTable :tableData="myData" :columns="myCols" :customConfig="myConfig" ref="activityTable" data-cy="activity-table"/>
        </div>
    </div>    
</template>

<script>
import { blackOrWhite } from '@/functions/utils.js';
import Sidebar from '@/components/nav/sidebar.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import MyTable from '@/components/table.vue';
import Modal from '@/components/modals/modal.vue';
import Info from '@/components/nav/info.vue';
import { taskFields, noteFields, checkInFields } from '@/functions/activities.js';

export default {
    components: { Sidebar, CustomSelect, CustomButton, MyTable, Modal, Info },
    data() {
        return {
            activityTypes: this.getAvailableActivityTypes(),
            categoryTitle: '',
            categoryColor: '',
            showCompleted: false,
            mode: 'Tasks',
            editingCategory: false,
            managerMode: false,
            myData: this.getDocs(),
            myCols: taskFields,
            myConfig: {
                cellClick: (e, cell) => {
                    if (cell._cell.column.field === 'completed') {
                        this.toggleComplete(cell._cell.row.data);
                    } else {
                        this.launchEdit(cell._cell.row.data);
                    }
                },
            },
            categories: this.$user.preferences.taskTags
        }
    },
    inject: ['posthog'],
    watch: {
        showCompleted: function() {
            this.refresh();
        },
        mode: function() {
            this.refresh();
        },
        managerMode: function() {
            this.refresh();
        }
    },
    methods: {
        blackOrWhite,
        getAvailableActivityTypes() {
            const result = ['Tasks', 'Notes'];
            if (this.$user.hasPower('viewOTCEvents')) result.push('Check-ins');
            return result.map(e => ({displayName: e}));
        },
        getDocType() {
            const result = [
                {mode: 'Tasks', docType: 'task'},
                {mode: 'Notes', docType: 'note'},
                {mode: 'Check-ins', docType: 'check-in'},
            ].find(e => e.mode == this.mode)?.docType || 'task';
            return result;
        },
        getDocs() {
            const docTypeToShow = this.getDocType();
            return this.store.state.notes
                .filter(element => element.docType === docTypeToShow)
                .filter(element => {
                    if (this.$user.hasPower('viewAllCheckins') && this.managerMode && docTypeToShow === 'check-in') {
                        return true;
                    }
                    return element.uid === this.$user.uid;
                })
                .filter(element => {
                    if (this.showCompleted === true) return true;
                    return !element.completed;
                });
        },
        getCols() {
            if (this.mode === 'Tasks') return taskFields;
            if (this.mode === 'Check-ins') return checkInFields.filter(f => 
                this.$user.hasPower('viewAllCheckins') && this.managerMode ? true : f.field !== 'author'
            );
            return noteFields;
        },
        launchAdd() {
            this.ee.emit('addTask', {mode: this.mode});
        },
        launchEdit(task) {
            this.ee.emit('editTask', task);
        },
        refresh() {
            this.myData = this.getDocs();
            this.myCols = this.getCols();
            if (!this.$refs.activityTable) return;
            this.$refs.activityTable.doMethod('setColumns', this.myCols);
            this.$refs.activityTable.doMethod('setData', this.myData);
        },
        toggleComplete(task) {
            task.completed = !task.completed;
            this.fb.messages.update(task.docID, task);
        },
        lookUpStudentName(id) {
            return this.store.state.studentData.find(record => record.studentRISEId == id)?._studentName || 'No student found'
        },
        launchAddCategory() {
            this.categoryColor = '';
            this.categoryTitle = '';
            this.editingCategory = false;
            this.$refs.categoryModal.show();
        },
        launchEditCategory(category) {
            this.categoryBeingEdited = category;
            this.categoryColor = category.color;
            this.categoryTitle = category.title;
            this.editingCategory = true;
            this.$refs.categoryModal.show();
        },
        deleteCategory(category) {
            // update tasks to remove this category
            this.myData.forEach(task => {
                task.categories = task.categories.filter(tag => {
                    if (tag.color == category.color && tag.title == category.title) return false;
                    return true;
                });
                this.fb.messages.update(task.docID, task);
            });

            this.categories = this.categories.filter(e => e!== category);
            this.$user.preferences.taskTags = this.categories;
            this.fb.user.updatePreference('taskTags', this.$user.preferences.taskTags);
            this.refresh();
        },
        getCategoryStyle(category) {
            return `background-color: ${category.color}; color: ${this.blackOrWhite(category.color)};`
        },
        addOrUpdateCategory() {
            if (this.editingCategory) {
                this.editCategory();
            } else {
                this.addCategory();
            }
        },
        editCategory() {
            const newTitle = this.categoryTitle || 'Untitled'
            const newColor = this.categoryColor || '#000000'
            // update tasks to remove this category
            this.myData.forEach(task => {
                task.categories.forEach(tag => {
                    if (tag.color == this.categoryBeingEdited.color && tag.title == this.categoryBeingEdited.title) {
                        tag.title = newTitle;
                        tag.color = newColor;
                    }
                });
                this.fb.messages.update(task.docID, task);
            });

            this.categoryBeingEdited.title = newTitle;
            this.categoryBeingEdited.color = newColor;

            this.fb.user.updatePreference('taskTags', this.$user.preferences.taskTags);
        },
        addCategory() {
            const categoryToAdd = {
                title: this.categoryTitle || 'Untitled',
                color: this.categoryColor || '#000000'
            };
            this.categories.push(categoryToAdd);
            this.fb.user.updatePreference('taskTags', this.$user.preferences.taskTags);
        },
        download() {
            this.$refs.activityTable.doMethod('download', 'xlsx', 'appData.xlsx', {sheetName:'App Download Data'});
        }
    },
    mounted() {
        this.ee.on('noteDataReceived', () => {
            this.refresh();
        });
    },
    activated() {
        // Send pageview to posthog
        this.posthog.capture('$pageview', {
            $current_url: '/activities'
        });
    }
}
</script>

<style lang="scss">

    .manager-area {
        margin-left: 5px;
    }
    .category-modal {
        .task-title {
            margin-top: 1.5rem;
        }
        .add-task-input {
            width: 400px;
            border: none;
            height: 30px;
            margin: 1rem 0;
        }
        .color-input {
            position: relative;
            top: 5px;
            left: 5px;
        }
        .category-color {
            margin-bottom: 10px;
        }
    }

    .show-completed-area {
        margin: 2rem 0 1rem;
        label {
            margin-left: 5px;
        }
    }
    .table-category {
        padding: 3px;
        margin-right: 4px;
        border-radius: 4px;
    }

    .date-overdue {
        background-color: var(--color-warning);
        color: var(--color-bg);
        padding: 3px;
        border-radius: 5px;
    }
    .date-approaching {
        background-color: var(--color-info);        
        color: var(--color-bg);
        padding: 3px;
        border-radius: 5px;
    }


</style>
